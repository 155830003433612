export const AdminDashboard = {
  Label: "home",
  Icon: "fa-home",
  RouterLink: "/admin/admin-dashboard",
  Childs: null,
  IsChildVisible: false,
};
export const Roles = {
  Label: "role",
  Icon: "fa-user-tag",
  RouterLink: "/admin/roles",
  Childs: null,
  IsChildVisible: false,
};
export const RouteList = {
  Label: "route",
  Icon: "fa-route",
  RouterLink: "/admin/line-list",
  Childs: null,
  IsChildVisible: false,
};
export const ScheduleList = {
  Label: "schedule",
  Icon: "fa-calendar",
  RouterLink: "/admin/schedule-list",
  Childs: null,
  IsChildVisible: false,
};
export const AdminTicketList = {
  Label: "ticket",
  Icon: "fa-book",
  RouterLink: "/admin/ticket-list",
  Childs: null,
  IsChildVisible: false,
};
export const AdminAgentList = {
  Label: "agent",
  Icon: "fa-user",
  RouterLink: "/admin/agent-list",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorList = {
  Label: "operator",
  Icon: "fa-landmark",
  RouterLink: "/admin/operator-list",
  Childs: null,
  IsChildVisible: false,
};
export const LocationList = {
  Label: "city",
  Icon: "fa-city",
  RouterLink: "/admin/location-list",
  Childs: null,
  IsChildVisible: false,
};
export const AdminAboutUs = {
  Label: "about",
  Icon: "fa-info-circle",
  RouterLink: "/admin/aboutus",
  Childs: null,
  IsChildVisible: false,
};

export const OperatorDashboard = {
  Label: "home",
  Icon: "fa-home",
  RouterLink: "/main/dailydashbard",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorRoute = {
  Label: "route",
  Icon: "fa-route",
  RouterLink: "/main/route",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorScheduleList = {
  Label: "schedule",
  Icon: "fa-tasks",
  RouterLink: "/main/trip-schedule",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorBusList = {
  Label: "bus",
  Icon: "fa-bus",
  RouterLink: "/main/bus",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorAgentList = {
  Label: "agent",
  Icon: "fa-user",
  RouterLink: "/main/operator-agent-list",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorDriverList = {
  Label: "driver",
  Icon: "fa-user",
  RouterLink: "/main/driver-list",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorReserveRequest = {
  Label: "reserveRequest",
  Icon: "fa-question-circle",
  RouterLink: "/main/reserve-request",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorRoutePrice = {
  Label: "routePrice",
  Icon: "fa-dollar-sign",
  RouterLink: "/main/operator-route-price",
  Childs: null,
  IsChildVisible: false,
};
export const SuperAgentList = {
  Label: "superAgent",
  Icon: "fas fa-users-cog",
  RouterLink: "/main/super-agent",
  Childs: null,
  IsChildVisible: false,
};

export const OperatorStaff = {
  Label: "operatorStaff",
  Icon: "far fa-users",
  RouterLink: "/main/operator-staff",
  Childs: null,
  IsChildVisible: false,
};

export const OperatorBooking = {
  Label: "booking",
  Icon: "fa-book",
  RouterLink: "/main/book",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorPhone = {
  Label: "supportPhone",
  Icon: "fa-phone",
  RouterLink: "/main/operator-phone",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorBank = {
  Label: "bank",
  Icon: "fa-university ",
  RouterLink: "/main/operator-bank",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorReport = {
  Label: "report",
  Icon: "fa-clone",
  RouterLink: "/main/report",
  Childs: null,
  IsChildVisible: false,
};
export const OperatorAboutUs = {
  Label: "about",
  Icon: "fa-info-circle",
  RouterLink: "/main/aboutus",
  Childs: null,
  IsChildVisible: false,
};

export const AgentDashboard = {
  Label: "home",
  Icon: "fa-home",
  RouterLink: "/agent/agent-dashboard",
  Childs: null,
  IsChildVisible: false,
};
export const AgentSearchTrip = {
  Label: "search",
  Icon: "fa-search",
  RouterLink: "/agent/search-trip",
  Childs: null,
  IsChildVisible: false,
};
export const AgentTicketList = {
  Label: "ticket",
  Icon: "fa-book",
  RouterLink: "/agent/ticket-list",
  Childs: null,
  IsChildVisible: false,
};
export const AgentChangePassword = {
  Label: "changePassword",
  Icon: "fa-key",
  RouterLink: "/agent/reset-password",
  Childs: null,
  IsChildVisible: false,
};
export const AgentAboutus = {
  Label: "about",
  Icon: "fa-info-circle",
  RouterLink: "/agent/aboutus",
  Childs: null,
  IsChildVisible: false,
};

export const SupperAgentDashboard = {
  Label: "home",
  Icon: "fa-home",
  RouterLink: "super-agent",
  Childs: null,
  IsChildVisible: false,
};

export const SupperAgentAgent = {
  Label: "Supper Agent List",
  Icon: "fa-home",
  RouterLink: "super-agent/agents",
  Childs: null,
  IsChildVisible: false,
};
export const SupperAgentReport = {
  Label: "Supper Agent Report",
  Icon: "fa-home",
  RouterLink: "super-agent/reports",
  Childs: null,
  IsChildVisible: false,
};

export const OperatorSerial = {
  Label : "operatorSerial",
  Icon : "far fa-qrcode",
  RouterLink : "/main/operator-serial",
  Childs : null,
  IsChildVisible : false
}

export const AuditLog = {
  Label : "auditLog",
  Icon : "fa-suitcase-rolling",
  RouterLink : "/admin/audit-log",
  Childs : null,
  IsChildVisible : false
}
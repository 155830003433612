<form [formGroup]="userform" (ngSubmit)="onClickChangePassword()">
  <div class="ui-grid p-offset-1 ui-grid-pad text-center " style="margin:40px; width: 500px;height: 400px;">
    <div style="font-size: x-large;">
      <p>{{'changePassword'|translate}}</p>
    </div>
    <div class="ui-grid-row" style="margin:20px;">
      <div class="ui-grid-col-4">
        <label>{{'currentPassword'|translate}} * </label>
      </div>
      <div class="ui-grid-col-8">
        <span class="ui-float-label">
          <input id="float-input" type="password" size="30" pInputText formControlName="oldPassword" required>
          <label for="float-input">{{'currentPassword'|translate}}</label>
        </span>
        <div class="p-col-12" *ngIf="
          userform.controls['oldPassword'].errors &&
          userform.controls['oldPassword'].dirty&&userform.controls['oldPassword'].touched">
          <p-message severity="error" text="password is required"
            *ngIf="userform.controls['oldPassword'].errors['required']">
          </p-message>
          <p-message severity="error" text="please Use 8 or more characters with a mix of letters, numbers & symbols"
            *ngIf="userform.controls['oldPassword'].errors['invalidPassword']">
          </p-message>
        </div>
      </div>

    </div>
    <div class="ui-grid-row" style="margin:20px;">
      <div class="ui-grid-col-4">
        <label>{{'password'|translate}} *</label>
      </div>
      <div class="ui-grid-col-8">
        <span class="ui-float-label">
          <input id="float-input" type="password" size="30" pPassword formControlName="newPassword" required>
          <label for="float-input">{{'password'|translate}}</label>
        </span>
        <div class="p-col-12" *ngIf="
        userform.controls['newPassword'].errors &&
        userform.controls['newPassword'].dirty&&userform.controls['newPassword'].touched">
        <p-message severity="error" text="password is required"
          *ngIf="userform.controls['newPassword'].errors['required']">
        </p-message>
        <p-message severity="error" text="please Use 8 or more characters with a mix of letters, numbers & symbols"
          *ngIf="userform.controls['newPassword'].errors['invalidPassword']">
        </p-message>
      </div>
      </div>

    </div>

    <div class="ui-grid-row" style="margin:20px;">
      <div class="ui-grid-col-4">
        <label>{{'confirmPassword'|translate}} *</label>
      </div>
      <div class="ui-grid-col-8">
        <span class="ui-float-label">
          <input id="float-input" type="password" size="30" pPassword formControlName="confirmPassword" required>
          <label for="float-input">{{'confirmPassword'|translate}}</label>
        </span>
        <div class="p-col-12" *ngIf="
        userform.controls['confirmPassword'].errors &&
        userform.controls['confirmPassword'].dirty
      ">
      <p-message severity="error" text="confirm password is required"
        *ngIf="userform.controls['confirmPassword'].errors['required']">
      </p-message>
      <p-message severity="error" text="password is mismatch"
        *ngIf="userform.controls['confirmPassword'].errors['passwordMismatch']">
      </p-message>
    </div>
      </div>
    </div>
    <div class="ui-grid-row p-offset-1">
      <div class="ui-grid-col-4">
        <button pButton type="button" label="{{'cancel'|translate}}" class="ui-button-raised"
          (click)="this.userform.reset()"></button>
      </div>
      <div class="ui-grid-col-1"></div>
      <div class="ui-grid-col-6">
        <button pButton type="button" label="{{'save'|translate}}" class="ui-button-raised"
          (click)="onClickChangePassword()" [disabled]="!userform.valid||disableSubmit"></button>
      </div>
    </div>
  </div>
</form>



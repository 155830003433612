import { NgModule } from "@angular/core";
import { NgPrimeModule } from "src/app/app.ngprime.module";
import { LanguageModule } from "src/app/shared/module/language.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomMinDirective } from "src/app/core/validators/custom-min-validator.directive";
import { CustomMaxDirective } from "src/app/core/validators/custom-max-validator.directive";
import { ErrorComponent } from "./shared/error/error.component";
import { CanCreateDirective } from './core/directives/can-create.directive';
import { CanUpdateDirective } from './core/directives/can-update.directive';
import { CanViewDirective } from './core/directives/can-view.directive';
import { CanDeleteDirective } from './core/directives/can-delete.directive';


@NgModule({
  imports: [],
  exports: [NgPrimeModule, LanguageModule, FormsModule, ReactiveFormsModule, CanCreateDirective, CanUpdateDirective, CanViewDirective, CanDeleteDirective],
  declarations: [CustomMinDirective, CustomMaxDirective, ErrorComponent, CanCreateDirective, CanUpdateDirective, CanViewDirective, CanDeleteDirective, ],
})
export class AppCommonModule {}


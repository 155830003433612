import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { PanelModule } from "primeng/panel";
import { ToastModule } from "primeng/toast";
import { MegaMenuModule } from "primeng/megamenu";
import { TableModule } from "primeng/table";
import { MessageModule } from "primeng/message";
import { CardModule } from "primeng/card";
import { ChartModule } from "primeng/chart";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CalendarModule } from "primeng/calendar";
import { SidebarModule } from "primeng/sidebar";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MessagesModule } from "primeng/messages";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { PasswordModule } from "primeng/password";
import { KeyFilterModule } from "primeng/keyfilter";
import { InputMaskModule } from "primeng/inputmask";
import { RadioButtonModule } from "primeng/radiobutton";
import { InputSwitchModule } from "primeng/inputswitch";
import { CheckboxModule } from "primeng/checkbox";
import { MultiSelectModule } from "primeng/multiselect";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MenubarModule } from "primeng/menubar";
import { MenuModule } from "primeng/menu";
@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    PanelModule,
    ToastModule,
    MegaMenuModule,
    TableModule,
    MessageModule,
    CardModule,
    ChartModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    BreadcrumbModule,
    CalendarModule,
    SidebarModule,
    DynamicDialogModule,
    InputTextareaModule,
    MessagesModule,
    DialogModule,
    DropdownModule,
    PasswordModule,
    InputMaskModule,
    KeyFilterModule,
    RadioButtonModule,
    InputSwitchModule,
    CheckboxModule,
    MultiSelectModule,
    ConfirmDialogModule,
    MenubarModule,
    MenuModule,
  ],
})
export class NgPrimeModule {}

import { Directive } from '@angular/core';

@Directive({
  selector: '[appCanDelete]'
})
export class CanDeleteDirective {

  constructor() { }

}

import { Injectable } from '@angular/core';
import { Permission } from '../models/user.model';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  permissions : Permission;
  constructor(
    private sessionService : SessionService
  ) {
   }

  getUserPermission(){
    return this.sessionService.getItem("currentUser").permissions;
  }

  canView(type : string){
    let permissions = this.getUserPermission();
    return permissions.view.includes(type.toUpperCase());
  }

  canCreate(type : string){
    let permissions = this.getUserPermission();
    return permissions.create.includes(type.toUpperCase());
  }

  canDelete(type : string){
    let permissions = this.getUserPermission();
    return permissions.delete.includes(type.toUpperCase());
  }

  canUpdate(type : string){
    let permissions = this.getUserPermission();
    return permissions.update.includes(type.toUpperCase());
  }
}

import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CustomvalidationService } from "src/app/core/services/customvalidation.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  userform: FormGroup;
  disableSubmit: boolean;
  constructor(
    private customValidator: CustomvalidationService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.userform = this.fb.group(
      {
        oldPassword: new FormControl("", Validators.required),
        newPassword: new FormControl("", Validators.required),
        confirmPassword: new FormControl("", [Validators.required]),
      },
      {
        validator: this.customValidator.MatchPassword(
          "newPassword",
          "confirmPassword"
        ),
      }
    );
  }
  onClickChangePassword() {
   console.log(this.userform.value);
  }

  get userFormControl() {
    return this.userform.controls;
  }
}

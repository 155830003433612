import { Directive } from '@angular/core';

@Directive({
  selector: '[appCanView]'
})
export class CanViewDirective {

  constructor() { }

}

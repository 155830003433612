import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '../services/permission.service';

@Directive({
  selector: '[appCanCreate]'
})
export class CanCreateDirective {


  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService : PermissionService

  ) { 
    
  }

  @Input()
  set appCanCreate(val : string[] | string){
    if(Array.isArray(val)){
      console.log(val);
      var canCreate = false;
      val.map((item) =>{
        canCreate = canCreate || this.permissionService.canCreate(item)
      });
      if(canCreate) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
    }
    else{
      if(this.permissionService.canCreate(val)){
        this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
    }

  }

}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RouteStateService } from "src/app/core/services/route-state.service";
import { SessionService } from "src/app/core/services/session.service";
import { SystemUser } from "src/app/core/models/system.user.model";
import { notification } from "src/app/core/models/notification.model";
import { UserIdleService } from "angular-user-idle";
import { ThemeService } from "src/app/core/services/theme.service";
import { UserContextService } from "src/app/core/services/user-context.service";
import { MenuDataService } from "src/app/core/services/menu-data.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.css"],
})
export class HeaderComponent implements OnInit {
  user: SystemUser;
  displayNotifications: boolean;
  notifications: notification[];
  headerUserImage;
  cities: any[];
  selectedCity1: any;
  locale: string;
  display: boolean;
  constructor(
    private router: Router,
    private routeStateService: RouteStateService,
    private sessionService: SessionService,
    private userIdle: UserIdleService,
    private themeService: ThemeService,
    private userContextService: UserContextService,
    private menuDataService: MenuDataService,
    public translate: TranslateService
  ) {
    this.displayNotifications = false;

    var selectedTheme = this.sessionService.getItem("selected-theme");
    if (selectedTheme) {
      this.selectTheme(selectedTheme);
    }
  }

  ngOnInit() {
    this.display = false;
    this.user = this.sessionService.getItem("currentUser");

    this.translate.setDefaultLang("en");
    let UserRoles;
    for (var i = 0; i < this.user.user.roles.length; i++) {
      UserRoles = this.user.user.roles[i];
    }
    if (UserRoles == "Operator") {
      this.headerUserImage = this.user.user.operator.code + ".jpg";
    } else {
      console.log("Admin");
      this.headerUserImage = "ezibus.png";
    }
    this.notifications = [];
    for (var i = 1; i <= 5; i++) {
      var notificationObj = new notification("Message " + i, new Date(), null);
      this.notifications.push(notificationObj);
    }
  }

  logout() {
    this.userIdle.stopWatching();
    this.routeStateService.removeAll();
    this.userContextService.logout();
    this.sessionService.removeItem("active-menu");
    this.router.navigate(["/login"]);
  }

  showNotificationSidebar() {
    this.displayNotifications = true;
  }

  toggleMenu() {
    this.menuDataService.toggleMenuBar.next(true);
  }

  selectTheme(theme: string) {
    this.sessionService.setItem("selected-theme", theme);
    this.themeService.selectTheme(theme);
  }
  onLanguageChange(event) {
    this.locale = event.target.value;
    if (
      this.locale == undefined ||
      this.locale == null ||
      this.locale.length == 0
    ) {
      this.locale = "en";
    }
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.locale);
    this.sessionService.setItem("ng-prime-language", this.locale);
  }
}

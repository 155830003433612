import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CustomvalidationService {
  constructor(private http: HttpClient) {
  }

  url = 'http://localhost:3000';

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[A-Z||a-z])(?=.*?[0-9]).{8,}$');
      const valid = regex.test(control.value);
      return valid ? null : {invalidPassword: true};
    };
  }

  UserNamepatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^[a-z0-9]{3,15}$');
      const valid = regex.test(control.value);
      return valid ? null : {invalidUserName: true};
    };
  }

  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];
      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (
        confirmPasswordControl.errors &&
        !confirmPasswordControl.errors.passwordMismatch
      ) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({passwordMismatch: true});
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }

  userNameValidator(userControl: AbstractControl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.validateUserName(userControl.value)) {
          console.log(this.validateUserName(userControl.value));
          resolve({userNameNotAvailable: true});
        } else {
          resolve(null);
        }
      }, 1000);
    });
  }

  validateUserName(userName: string) {
    const UserList = ['ankit', 'admin', 'user', 'superuser'];
    return UserList.indexOf(userName) > -1;
  }

  getAgent(userName: string) {
    /*
    return this.http
      .get(this.url + '/agent?userName=' + userName)
      .pipe(map((response: any) => response.json()));
    */
    return this.http
      .get<any>(this.url + '/agent?userName=' + userName)
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  }

  // agentUserNameValidator(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } => {
  //     if (!control.value) {
  //       return null;
  //     }
  //     return new Promise((resolve) => {
  //       setTimeout(() => {
  //         this.getAgent(control.value).then((data) => {
  //           this.agents = data;
  //           console.log(this.agents.length);
  //           if (this.agents.length != 0) {
  //             resolve({ userNameNotAvailable: true });
  //           } else {
  //             resolve(null);
  //           }
  //         });
  //       }, 1000);
  //     });
  //   };
  // }
}

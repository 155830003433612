import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CustomMenuItem } from "../models/menu-item.model";
import {
  AdminAboutUs,
  AdminAgentList,
  AdminDashboard,
  AdminTicketList,
  AgentAboutus,
  AgentChangePassword,
  AgentDashboard,
  AgentSearchTrip,
  AgentTicketList,
  LocationList,
  OperatorList,
  OperatorAboutUs,
  OperatorAgentList,
  OperatorBank,
  OperatorBooking,
  OperatorBusList,
  OperatorDashboard,
  OperatorDriverList,
  OperatorPhone,
  SuperAgentList,
  OperatorReport,
  OperatorReserveRequest,
  OperatorRoute,
  OperatorRoutePrice,
  OperatorScheduleList,
  OperatorStaff,
  Roles,
  RouteList,
  ScheduleList,
  SupperAgentDashboard,
  SupperAgentAgent,
  SupperAgentReport,
  OperatorSerial, 
  AuditLog
} from "../constants/menu-data";

import { PermissionList } from "../constants/permissions";
import { Permission } from "../models/user.model";

@Injectable({
  providedIn: "root",
})
export class MenuDataService {
  public toggleMenuBar: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  getMenuListBasedOnPermission(permissions: Permission) {
    var menuList = [];
    let permissionObject: any = {
      ADMINDASHBOARD: AdminDashboard,
      ADMINROUTE: RouteList,
      ADMINSCHEDULE: ScheduleList,
      ADMINAGENT: AdminAgentList,
      ADMINTRANSACTION: AdminTicketList,
      OPERATOR: OperatorList,
      LOCATION: LocationList,
      ROLE: Roles,
      ADMINABOUTUS: AdminAboutUs,
      AGENTDASHBOARD: AgentDashboard,
      AGENTSEARCHTRIP: AgentSearchTrip,
      AGENTTICKETLIST: AgentTicketList,
      AGENTCHANGEPASSWORD: AgentChangePassword,
      AGENTABOUTUS: AgentAboutus,
      OPERATORDASHBOARD: OperatorDashboard,
      OPERATORROUTE: OperatorRoute,
      OPERATORSCHEDULE: OperatorScheduleList,
      OPERATORBUS: OperatorBusList,
      OPERATORAGENT: OperatorAgentList,
      OPERATORDRIVER: OperatorDriverList,
      OPERATORRESERVEREQUEST: OperatorReserveRequest,
      OPERATORROUTEPRICE: OperatorRoutePrice,
      SUPERAGENT : SuperAgentList,
      OPERATORSTAFF: OperatorStaff,
      OPERATORBOOKING: OperatorBooking,
      OPERATORPHONE: OperatorPhone,
      OPERATORBANKACCOUNT: OperatorBank,
      OPERATORSERIAL : OperatorSerial,
      OPERATORREPORT: OperatorReport,
      OPERATORABOUTUS: OperatorAboutUs,
      SUPERAGENTDASHBOARD: SupperAgentDashboard,
      SUPERAGENTAGENT: SupperAgentAgent,
      SUPERAGENTREPORT: SupperAgentReport,
      AUDITLOG : AuditLog
    };

    const view = permissions.view;

    var modules = Object.keys(permissionObject);
    modules.map((i) => {
      if (view.includes(i)) {
        menuList.push(permissionObject[i]);
      }
    });

    return menuList;
  }

  getMenuList(role: string): CustomMenuItem[] {
    if (role == "Administrator") {
      return [
        AdminDashboard,
        RouteList,
        ScheduleList,
        AdminTicketList,
        AdminAgentList,
        OperatorList,
        LocationList,
        Roles,
        AdminAboutUs,
      ];
    } else if (role == "Agent" || role == "OperatorAgent") {
      return [
        AgentDashboard,
        AgentSearchTrip,
        AgentTicketList,
        AgentChangePassword,
        AgentAboutus,
      ];
    } else if (role == "EziSuperviser") {
      return [OperatorReport, OperatorAboutUs];
    } else if (role == "Operator") {
      return [
        OperatorDashboard,
        OperatorRoute,
        OperatorScheduleList,
        OperatorBusList,
        OperatorAgentList,
        OperatorDriverList,
        OperatorReserveRequest,
        OperatorRoutePrice,
        SuperAgentList,
        OperatorStaff,
        OperatorBooking,
        OperatorPhone,
        OperatorBank,
        OperatorReport,
        OperatorAboutUs,
      ];
    }
  }
}

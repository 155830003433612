import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/gaurds/auth.gaurd";
import { LayoutComponent } from "src/app/shared/layout/layout.component";
import { ErrorComponent } from "./shared/error/error.component";
const appRoutes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("src/app/features/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "print/ticket",
    loadChildren: () =>
      import("src/app/Agentfeatures/ticket-print/ticket-print.module").then(
        (m) => m.TicketPrintModule
      ),
  },
  {
    path : "super-agent",
    component : LayoutComponent,
    loadChildren : () =>
      import("src/app/features/super-agent/super-agent.module").then(
        m => m.SuperAgentModule
      ),
  },
  {
    path: "admin",
    component: LayoutComponent,
    children: [
      {
        path: "admin-dashboard",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/admin-dashboard/admin-dashboard.module"
          ).then((m) => m.AdminDashboardModule),
        canActivate: [AuthGuard],
      },

      {
        path: "register-agent",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/register-user/register-user.module"
          ).then((m) => m.RegisterUserModule),
        canActivate: [AuthGuard],
      },
      {
        path: "agent-list",
        loadChildren: () =>
          import("src/app/Adminfeatures/agent-list/agent-list.module").then(
            (m) => m.AgentListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "register-operator",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/register-operator/register-operator.module"
          ).then((m) => m.RegisterOperatorModule),
        canActivate: [AuthGuard],
      },
      {
        path: "operator-list",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/operator-list/operator-list.module"
          ).then((m) => m.OperatorListModule),
        canActivate: [AuthGuard],
      },

      {
        path: "line-list",
        loadChildren: () =>
          import("src/app/Adminfeatures/line-list/line-list.module").then(
            (m) => m.LineListModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "register-line",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/register-line/register-line.module"
          ).then((m) => m.RegisterLineModule),
        canActivate: [AuthGuard],
      },
      {
        path: "register-station",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/register-stations/register-stations.module"
          ).then((m) => m.RegisterStationsModule),
        canActivate: [AuthGuard],
      },
      {
        path: "register-location",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/register-location/register-location.module"
          ).then((m) => m.RegisterLocationModule),
        canActivate: [AuthGuard],
      },

      {
        path: "schedule-list",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/schedule-list/schedule-list.module"
          ).then((m) => m.ScheduleListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "ticket-list",
        loadChildren: () =>
          import("src/app/Adminfeatures/ticket-list/ticket-list.model").then(
            (m) => m.TicketListModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "location-list",
        loadChildren: () =>
          import(
            "src/app/Adminfeatures/location-list/location-list.module"
          ).then((m) => m.LocationListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "roles",
        loadChildren: () =>
          import("src/app/Adminfeatures/roles/roles.module").then(
            (m) => m.RolesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "aboutus",
        loadChildren: () =>
          import("src/app/features/aboutus/aboutus.module").then(
            (m) => m.AboutUsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path : "audit-log",
        loadChildren : () => import("src/app/Adminfeatures/audit-log/audit-log.module")
                            .then( (m) => m.AuditLogModule ),
        canActivate : [AuthGuard]
      },
      {
        path: "contactus",
        loadChildren: () =>
          import("src/app/features/contactus/contactus.module").then(
            (m) => m.ContactUsModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "main",
    component: LayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("src/app/features/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "register-driver",
        loadChildren: () =>
          import(
            "src/app/features/register-driver/register-driver.module"
          ).then((m) => m.RegisterDriverModule),
      },
      
      {
        path : "operator-serial",
        loadChildren : () => 
          import("src/app/features/operator-serial/operator-serial.module").then(
            (m) => m.OperatorSerialModule
          ),
          canActivate: [AuthGuard],
      },
      {
        path: "register-agent",
        loadChildren: () =>
          import("src/app/features/register-agent/register-agent.model").then(
            (m) => m.RegisterAgentModule
          ),
      },
      {
        path: "bus",
        loadChildren: () =>
          import("src/app/features/bus/bus.module").then((m) => m.BusModule),
        canActivate: [AuthGuard],
      },
      {
        path: "registerbus",
        loadChildren: () =>
          import("src/app/features/register-bus/register-bus.model").then(
            (m) => m.RegisterBusModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "route",
        loadChildren: () =>
          import("src/app/features/route/route.module").then(
            (m) => m.RouteModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "operator-route-price",
        loadChildren: () =>
          import(
            "src/app/features/operator-route-price/operator-route-price.module"
          ).then((m) => m.OperatorRoutePriceModule),
        canActivate: [AuthGuard],
      },
      {
        path: "operator-phone",
        loadChildren: () =>
          import("src/app/features/operator-phone/operator-phone.module").then(
            (m) => m.OperatorPhoneModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "reset-password",
        loadChildren: () =>
          import("src/app/shared/forms/reset-password/reset-password.module").then(
            (m) => m.ResetPasswordModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "operator-staff",
        loadChildren: () =>
          import("src/app/features/operator-staff/operator-staff.module").then(
            (m) => m.OperatorStaffModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "operator-bank",
        loadChildren: () =>
          import("src/app/features/operator-bank/operator-bank.module").then(
            (m) => m.OperatorBankModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "reserve-request",
        loadChildren: () =>
          import(
            "src/app/features/reserve-request/reserve-request.module"
          ).then((m) => m.ReserveRequestModule),
        canActivate: [AuthGuard],
      },
      {
        path: "operator-agent-list",
        loadChildren: () =>
          import("src/app/features/operator-agent/operator-agent.modules").then(
            (m) => m.OperatorAgentModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "driver-list",
        loadChildren: () =>
          import("src/app/features/driver-list/driver-list.module").then(
            (m) => m.DriverListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "trip-schedule",
        loadChildren: () =>
          import("src/app/features/trip-schedule/trip-schedule.module").then(
            (m) => m.TripScheduleModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "super-agent",
        loadChildren: () =>
          import("src/app/features/operator-super-agent/operator-super-agent.module").then(
            (m) => m.OperatorSuperAgentModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "book",
        loadChildren: () =>
          import("src/app/features/booking/booking.module").then(
            (m) => m.BookingModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "book/schedule",
        loadChildren: () =>
          import(
            "src/app/features/booking-by-schedule/booking-by-schedule.module"
          ).then((m) => m.BookingByScheduleModule),
        canActivate: [AuthGuard],
      },
      {
        path: "montlydashbard",
        loadChildren: () =>
          import(
            "src/app/features/monthly-dashboard/monthly-dashboard.module"
          ).then((m) => m.MonthlyDashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: "dailydashbard",
        loadChildren: () =>
          import("src/app/features/daily-dashboard/daily-dashboard-model").then(
            (m) => m.DailyDashboardModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "aboutus",
        loadChildren: () =>
          import("src/app/features/aboutus/aboutus.module").then(
            (m) => m.AboutUsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "contactus",
        loadChildren: () =>
          import("src/app/features/contactus/contactus.module").then(
            (m) => m.ContactUsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "report",
        loadChildren: () =>
          import(
            "src/app/features/generate-report/generate-report.module"
          ).then((m) => m.GenerateReportModule),
        canActivate: [AuthGuard],
      },
      {
        path: "update-profile",
        loadChildren: () =>
          import("src/app/features/update-profile/update-profile.module").then(
            (m) => m.UpdateProfileModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "agent",
    component: LayoutComponent,
    children: [
      {
        path: "agent-dashboard",
        loadChildren: () =>
          import(
            "src/app/Agentfeatures/agent-dashboard/agent-dashboard.module"
          ).then((m) => m.AgentDashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: "search-trip",
        loadChildren: () =>
          import("src/app/Agentfeatures/search-trip/search-trip.module").then(
            (m) => m.SearchTripModule
          ),
      },
      {
        path: "trip-list",
        loadChildren: () =>
          import("src/app/Agentfeatures/trip-list/trip-lst.module").then(
            (m) => m.TripListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "ticket-list",
        loadChildren: () =>
          import("src/app/Agentfeatures/ticket-list/ticket-list.module").then(
            (m) => m.TicketListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "aboutus",
        loadChildren: () =>
          import("src/app/features/aboutus/aboutus.module").then(
            (m) => m.AboutUsModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "contactus",
        loadChildren: () =>
          import("src/app/features/contactus/contactus.module").then(
            (m) => m.ContactUsModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "error",
    component: ErrorComponent,
    //loadChildren: () => import('src/app/shared/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
